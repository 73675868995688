<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="1500px" :fullscreen="isMainFullscreen">
      <v-card class="parent-dialog-card d-flex flex-column">
        <!-- Header -->
        <v-toolbar dark color="#179fdb" flat class="toolbar-fixed">
          <v-icon large color="#004e82">mdi-chat-plus</v-icon>
          <v-card-title class="text-h5">platform X - CoPilot</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="isMainFullscreen = !isMainFullscreen">
            <v-icon>{{ isMainFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
          </v-btn>
          <v-btn icon dark @click.stop="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <!-- Main Chat Area -->
        <v-card-text class="parent-messages" @scroll="handleScroll($event, 'main')" ref="mainChatArea">
          <div v-for="(item, index) in displayedMessages" :key="index" class="my-2">
            <!-- User Message Container -->
            <div v-if="item.from === 'user'" class="message-d-flex justify-end">
              <div class="blue--text mr-3" style="background-color:azure;">
                {{ item.msg[0] }}
              </div>
              <v-avatar color="#0a6aa8" size="36">
                <span class="white--text">N</span>
              </v-avatar>
            </div>

            <!-- Bot Message Container -->
            <div v-else class="message-d-flex">
              <v-avatar color="#004e82" size="36">
                <span class="white--text">B</span>
              </v-avatar>

              <!-- Bot Message Content -->
              <div v-if="!item.isLoading" class="black--text ml-3 message-container">
                <div v-for="(entry, entryIndex) in item.msg" :key="entryIndex">
                  <div v-html="entry"></div>
                </div>

                <!-- Sources, Thread, and Rating Buttons (only shown when stream is complete) -->
                <template v-if="item.streamComplete">
                  <!-- Sources -->
                  <div v-if="item.sources && item.sources.length > 0">
                    Mögliche Quellen:
                    <span v-for="entry in item.sources" :key="entry['text_id_url']">
                      <a :href="entry['text_id_url']" target="_blank">{{ entry["title"] }}</a>&nbsp;&nbsp;
                    </span>
                  </div>

                  <!-- Thread and Rating Buttons -->
                  <div v-if="index !== 0" class="d-flex align-center mt-2">
                    <!-- Thread Button -->
                    <v-btn small text color="grey" @click="startThread(item)" class="mr-2">
                      <v-icon small class="mr-1">mdi-chat-plus-outline</v-icon>
                      {{
                        item.threadCount
                          ? `${item.threadCount} Folgenachrichten`
                          : 'Folgefragen im neuen Fenster'
                      }}
                    </v-btn>
                  </div>

                  <!-- Like/Dislike -->
                  <div v-if="index !== 0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="item.likeClicked" v-bind="attrs" v-on="on" tile icon color="blue lighten-2"
                          @click.stop="onLikeClicked(index, 1)">
                          <v-icon>mdi-thumb-up-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Klicke hier, um die Leistung unserer Suchergebnisse zu
                        verbessern.</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="item.likeClicked" v-bind="attrs" v-on="on" tile icon color="red lighten-2"
                          @click.stop="onLikeClicked(index, 0)">
                          <v-icon>mdi-thumb-down-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Klicke hier, um die Leistung unserer Suchergebnisse zu
                        verbessern.</span>
                    </v-tooltip>
                  </div>
                </template>
              </div>

              <!-- Loading State -->
              <div v-if="item.isLoading" class="black--text ml-5">
                <v-row>
                  <v-progress-circular indeterminate color="#004e82"></v-progress-circular>
                </v-row>
                <v-row>
                  <div v-for="(entry, loadingIndex) in testContent" :key="`loading-${loadingIndex}`">
                    <div v-html="entry"></div>
                  </div>
                </v-row>
              </div>
              <!-- Scroll anchor for main chat -->
              <div ref="mainScrollAnchor" class="scroll-anchor"></div>
            </div>
          </div>


        </v-card-text>

        <!-- Thread Dialog -->
        <v-dialog v-model="showThreadDialog" max-width="700px" :fullscreen="isThreadFullscreen">
          <v-card class="thread-dialog-card d-flex flex-column">
            <v-toolbar dark color="#179fdb" flat class="toolbar-fixed">
              <v-icon large color="#004e82">mdi-chat-processing</v-icon>
              <v-card-title class="text-h6">Folgefragen</v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="isThreadFullscreen = !isThreadFullscreen">
                <v-icon>{{ isThreadFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
              </v-btn>
              <v-btn icon dark @click="showThreadDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-divider></v-divider>

            <v-card-text class="thread-messages" @scroll="handleScroll($event, 'thread')" ref="threadChatArea">
              <!-- Initial User Message -->
              <div v-if="activeThread && activeThread.parentMessage" class="message-d-flex justify-end my-2">
                <div class="blue--text mr-3" style="background-color:azure;">
                  {{ activeThread.parentMessage.userMessage }}
                </div>
              </div>
              <!-- Initial Bot Response -->
              <div v-if="activeThread && activeThread.parentMessage" class="message-d-flex my-2">
                <div class="black--text ml-3 message-container">
                  <div v-html="activeThread.parentMessage.msg[0]"></div>
                  <!-- Sources for Initial Bot Message -->
                  <div v-if="activeThread.parentMessage.sources && activeThread.parentMessage.sources.length > 0" class="mt-2">
                    Mögliche Quellen:
                    <span v-for="entry in activeThread.parentMessage.sources" :key="entry['text_id_url']">
                      <a :href="entry['text_id_url']" target="_blank">{{ entry["title"] }}</a>&nbsp;&nbsp;
                    </span>
                  </div>
                </div>
              </div>
              <!-- Thread Messages -->
              <div v-for="(message, threadIndex) in activeThread && activeThread.messages
                ? activeThread.messages
                : []" :key="`thread-${threadIndex}`" class="my-2">
                <!-- User Message Container -->
                <div v-if="message.from === 'user'" class="message-d-flex justify-end">
                  <div class="blue--text mr-3" style="background-color:azure;">
                    {{ message.msg[0] }}
                  </div>
                </div>

                <!-- Bot Message Container -->
                <div v-else class="message-d-flex">
                  <!-- Bot Message Content -->
                  <div v-if="!message.isLoading" class="black--text ml-3 message-container">
                    <div v-for="(entry, entryIndex) in message.msg" :key="entryIndex">
                      <div v-html="entry"></div>
                    </div>

                    <!-- Sources for Thread Messages -->
                    <div v-if="message.sources && message.sources.length > 0" class="mt-2">
                      Mögliche Quellen:
                      <span v-for="entry in message.sources" :key="entry['text_id_url']">
                        <a :href="entry['text_id_url']" target="_blank">{{ entry["title"] }}</a>&nbsp;&nbsp;
                      </span>
                    </div>
                  </div>

                  <!-- Loading State -->
                  <div v-if="message.isLoading" class="black--text ml-5">
                    <v-row>
                      <v-progress-circular indeterminate color="#004e82"></v-progress-circular>
                    </v-row>
                    <v-row>
                      <div v-for="(entry, loadingIndex) in testContent" :key="`loading-${loadingIndex}`">
                        <div v-html="entry"></div>
                      </div>
                    </v-row>
                  </div>
                </div>
              </div>
              <!-- Scroll anchor for thread -->
              <div ref="threadScrollAnchor" class="scroll-anchor"></div>
            </v-card-text>

            <v-divider></v-divider>

            <!-- Thread Input -->
            <v-card-actions>
              <v-text-field v-model="threadMsg" placeholder="Folgefrage..." @keypress.enter="sendThreadMessage"
                full-width hide-details></v-text-field>
              <v-btn icon class="ml-2" @click="sendThreadMessage">
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-divider></v-divider>

        <!-- Main Input -->
        <v-card-actions>
          <v-container class="ma-0 pa-0" fluid>
            <v-row no-gutters>
              <v-col>
                <div class="d-flex flex-row align-center">
                  <v-text-field v-model="msg" placeholder="Stelle eine Frage"
                    @keypress.enter="onSendClick"></v-text-field>
                  <v-btn icon class="ml-4" @click="onSendClick">
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import axios from 'axios';
import { mapGetters } from 'vuex';
import { createChatBotQuestion, updateChatBotQuestion } from '@/graphql/mutations';
import { API } from 'aws-amplify';
import { marked } from 'marked';

export default {
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      jwt: '',
      switchOn: true,
      chat: [],
      msg: null,
      threadMsg: null,
      tokenBuffer: '',
      testContent: '',
      showThreadDialog: false,
      isThreadFullscreen: false,
      isMainFullscreen: false,
      showScrollTop: false,
      activeThread: null,
      threads: {},
      shouldMainAutoScroll: true,
      shouldThreadAutoScroll: true,
      streamComplete: false,
      scrollPositions: {
        main: 0,
        threads: {}
      },
      input: {
        // baseUrl: 'https://r5oqmf6m2cwyzkt7cpmz2rpohu0cxxqh.lambda-url.eu-central-1.on.aws/', //new prod
        // baseUrl: 'https://oagrtdcsnijsy77426vcpjmlqq0zsvef.lambda-url.eu-central-1.on.aws/', //prod alt
        // baseUrl: 'https://wag3g36pbtludjriu2z2qr3hli0pvofg.lambda-url.eu-central-1.on.aws/', //dev
        baseUrl: process.env.VUE_APP_COPILOT_RETRIVER_URL,
        // baseUrl: 'http://localhost:8000/', //local
        modelName: 'gpt-4o-2024-11-20',
        // modelName: 'gpt-4o-mini',
        k: 14
      },
      data: {
        answer_length: 0,
        chat_model: '',
        helpful: 0,
        question: '',
        timestamp: 0,
        user: ''
      },
      likedButtonClicked: false,
      test: '123',
      mainScrollObserver: null,
      threadScrollObserver: null,
      isMainAtBottom: true,
      isThreadAtBottom: true,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    displayedMessages() {
      return this.chat.map(message => ({
        ...message,
        threadCount: this.getThreadCount(message)
      }));
    },
    ...mapGetters(['jwtToken', 'kurzel'])
  },
  created() {
    this.chat.push(
      this.createMessage('sushant', [
        'Hallo und herzlich willkommen! Kann ich Dir behilflich sein?'
      ])
    );
    this.loadThreadsFromStorage();
    this.loadScrollPositions();
  },
  mounted() {
    // Set up observers
    this.setupScrollObservers();
  },
  beforeDestroy() {
    // Clean up observers
    if (this.mainScrollObserver) {
      this.mainScrollObserver.disconnect();
    }
    if (this.threadScrollObserver) {
      this.threadScrollObserver.disconnect();
    }
  },
  methods: {
    generateMessageId() {
      return `msg_${Date.now()}_${Math.random()
        .toString(36)
        .substr(2, 9)}`;
    },

    createMessage(from, msg, sources = [], isLoading = false, questionId = null) {
      return {
        from,
        msg: Array.isArray(msg) ? msg : [msg],
        sources: sources,
        isLoading,
        likeClicked: false,
        id: this.generateMessageId(),
        question_id: questionId,
        streamComplete: false
      };
    },

    async handleStreamingResponse(reader, messageIndex, isThread = false, questionId) {
      const decoder = new TextDecoder();
      let tokenBuffer = '';
      let sources = [];
      let messages = isThread ? this.activeThread.messages : this.chat;
      let isFirstToken = true;

      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            const finalResult = marked.parse(tokenBuffer);
            messages.splice(messageIndex, 1); // Remove loading message
            const newMessage = this.createMessage('sushant', finalResult, sources, false, questionId);
            newMessage.streamComplete = true;  // Set streamComplete to true when stream is done
            messages.push(newMessage);
            messages[messages.length - 1].sources = sources;

            if (isThread) {
              this.saveThreadsToStorage();
            }

            // Ensure scroll to bottom after completion
            this.$nextTick(() => {
              const chatArea = isThread ? this.$refs.threadChatArea : this.$refs.mainChatArea;
              if (chatArea && (isThread ? this.shouldThreadAutoScroll : this.shouldMainAutoScroll)) {
                this.scrollToBottom(chatArea);
              }
            });
            break;
          }

          const partialBuffer = decoder.decode(value, { stream: true });
          const lines = partialBuffer.split('\0');

          for (const line of lines) {
            if (line.startsWith('source:')) {
              const source = line.slice(7);
              sources.push(JSON.parse(source));
            } else if (line.startsWith('data:')) {
              const tokens = line.slice(5);
              tokenBuffer += tokens;

              // On first token, clear the loading message
              if (isFirstToken) {
                messages[messageIndex].msg = [''];
                messages[messageIndex].isLoading = false;
                isFirstToken = false;
              }

              const newMarkdown = tokenBuffer;
              if (newMarkdown.includes('\n')) {
                const lastIndex = this.findLastNewlineIndex(newMarkdown);
                const part1 = newMarkdown.substring(0, lastIndex);
                const part2 = newMarkdown.substring(lastIndex);
                messages[messageIndex].msg = [marked.parse(part1) + part2];
              } else {
                messages[messageIndex].msg = [tokenBuffer];
              }

              // Scroll during streaming if auto-scroll is enabled
              this.$nextTick(() => {
                const chatArea = isThread ? this.$refs.threadChatArea : this.$refs.mainChatArea;
                if (chatArea && (isThread ? this.shouldThreadAutoScroll : this.shouldMainAutoScroll)) {
                  this.scrollToBottom(chatArea);
                }
              });

              if (isThread) {
                this.saveThreadsToStorage();
              }
            }
          }
        }
      } catch (error) {
        console.error('Error in streaming response:', error);
        messages.splice(messageIndex, 1);
        messages.push(
          this.createMessage(
            'sushant',
            'Es tut mir leid, etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.',
            [],
            false,
            questionId
          )
        );

        if (isThread) {
          this.saveThreadsToStorage();
        }
      }
    },

    findLastNewlineIndex(text) {
      let lastIndex = text.lastIndexOf('\n');
      while (lastIndex > 0 && text[lastIndex - 1] === '\n') {
        lastIndex--;
      }
      return lastIndex;
    },

    async makeApiCall(question, messageIndex, isThread = false, questionId) {
      const url = this.buildApiUrl();
      const messages = this.formatChatHistory(question, isThread ? this.activeThread : null);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: this.jwtToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          messages: messages,
          k: this.input.k,
          model: this.input.modelName,
          stream: true
        })
      });

      await this.handleStreamingResponse(
        response.body.getReader(),
        messageIndex,
        isThread,
        questionId
      );
    },
    getThreadCount(message) {
      if (!message.id) return 0;
      const thread = this.threads[message.id];
      return thread ? thread.messages.length : 0;
    },
    async onLikeClicked(index, like) {
      this.data.timestamp = Math.floor(Date.now() / 1000);
      const lastAnswer = [...this.chat].pop();
      const lastQuestion = this.chat[this.chat.length - 2].msg;
      const input = {
        id: this.chat[index].question_id,
        answer_length: JSON.stringify(lastAnswer.msg).length,
        chat_model: this.input.modelName,
        helpful: like,
        feedback: 1,
        question: lastQuestion,
        timestamp: Math.floor(Date.now() / 1000),
        user: this.kurzel
      };
      await API.graphql({
        query: updateChatBotQuestion,
        variables: { input: input }
      });
      this.chat[index].likeClicked = true;
    },
    async saveQuestion(question) {
      const input = {
        answer_length: 0,
        chat_model: this.input.modelName,
        helpful: 0,
        feedback: 0,
        question: question,
        timestamp: Math.floor(Date.now() / 1000),
        user: this.kurzel
      };
      try {
        let myResp = await API.graphql({
          query: createChatBotQuestion,
          variables: { input: input }
        });
        return myResp.data.createChatBotQuestion.id;
      } catch (error) {
        return 0;
      }
    },
    saveThreadsToStorage() {
      try {
        const threadsToSave = JSON.parse(JSON.stringify(this.threads)); // Deep clone to avoid reference issues
        localStorage.setItem('chatThreads', JSON.stringify(threadsToSave));
      } catch (error) {
        console.error('Error saving threads to storage:', error);
      }
    },

    loadThreadsFromStorage() {
      try {
        const savedThreads = localStorage.getItem('chatThreads');
        if (savedThreads) {
          const parsedThreads = JSON.parse(savedThreads);
          // Validate thread structure
          Object.keys(parsedThreads).forEach(key => {
            const thread = parsedThreads[key];
            if (!thread.messages) {
              thread.messages = [];
            }
            if (!Array.isArray(thread.messages)) {
              thread.messages = [];
            }
          });
          this.threads = parsedThreads;
        }
      } catch (error) {
        console.error('Error loading threads from storage:', error);
        this.threads = {};
      }
    },

    startThread(message) {
      if (!message.id) {
        message.id = this.generateMessageId();
      }

      if (!this.threads[message.id]) {
        // Find the user message that came before this bot message
        const messageIndex = this.chat.findIndex(m => m.id === message.id);
        const userMessage = messageIndex > 0 ? this.chat[messageIndex - 1].msg[0] : '';

        // Use Vue.set to ensure reactivity
        this.$set(this.threads, message.id, {
          parentMessage: {
            ...message,
            userMessage: userMessage
          },
          messages: []
        });
      }

      // Ensure thread structure is valid
      if (!this.threads[message.id].messages) {
        this.$set(this.threads[message.id], 'messages', []);
      }

      this.activeThread = this.threads[message.id];
      this.saveThreadsToStorage();
      this.showThreadDialog = true;
    },
    async sendThreadMessage() {
      if (!this.threadMsg || !this.threadMsg.trim() || !this.activeThread) return;

      this.shouldThreadAutoScroll = true;

      const newMessages = [...this.activeThread.messages];
      newMessages.push(this.createMessage('user', this.threadMsg));
      this.$set(this.activeThread, 'messages', newMessages);

      // Scroll to bottom immediately after user message
      this.$nextTick(() => this.scrollToLastMessage('thread'));

      // Create loading message with proper loading state
      const loadingMessage = this.createMessage('sushant', ['Generating response...'], [], true);
      newMessages.push(loadingMessage);
      this.$set(this.activeThread, 'messages', newMessages);

      const messageIndex = this.activeThread.messages.length - 1;
      const question = this.threadMsg;
      this.threadMsg = null;

      this.saveThreadsToStorage();
      let questionId = await this.saveQuestion(this.msg);
      await this.makeApiCall(question, messageIndex, true, questionId);
    },
    buildApiUrl() {
      return this.input.baseUrl + 'chat';
    },
    formatChatHistory(newMessage, thread = null) {
      const messages = [];

      if (thread) {
        // For threads, include parent message context and thread history
        if (thread.parentMessage) {
          // Add the original user question
          messages.push({
            role: 'user',
            content: thread.parentMessage.userMessage
          });

          // Add the original bot response
          messages.push({
            role: 'assistant',
            content: Array.isArray(thread.parentMessage.msg)
              ? thread.parentMessage.msg.join('\n')
              : thread.parentMessage.msg
          });
        }

        // Add thread message history
        for (const message of thread.messages) {
          if (message.from === 'user') {
            messages.push({
              role: 'user',
              content: Array.isArray(message.msg) ? message.msg[0] : message.msg
            });
          } else if (message.from === 'sushant' && !message.isLoading) {
            messages.push({
              role: 'assistant',
              content: Array.isArray(message.msg) ? message.msg.join('\n') : message.msg
            });
          }
        }
      }

      // Add new message
      if (newMessage) {
        messages.push({
          role: 'user',
          content: newMessage
        });
      }

      return messages;
    },
    async onSendClick() {
      if (!this.msg || !this.msg.trim()) return;

      this.shouldMainAutoScroll = true;
      this.likedButtonClicked = false;
      this.chat.push(this.createMessage('user', this.msg));

      // Wait for DOM update and scroll to bottom
      await this.$nextTick();
      if (this.$refs.mainChatArea) {
        this.scrollToBottom(this.$refs.mainChatArea);
      }

      // Create loading message with proper loading state
      const loadingMessage = this.createMessage('sushant', ['Generating response...'], [], true);
      this.chat.push(loadingMessage);

      const messageIndex = this.chat.length - 1;
      const question = this.msg;
      this.msg = null;
      this.testContent = '';
      let questionId = await this.saveQuestion(question);
      await this.makeApiCall(question, messageIndex, false, questionId);

      // Ensure we scroll to bottom after the response
      await this.$nextTick();
      if (this.$refs.mainChatArea) {
        this.scrollToBottom(this.$refs.mainChatArea);
      }
    },
    handleThreadScroll(event) {
      const element = event.target;
      const atBottom = this.isAtBottom(element);
      this.showScrollTop = element.scrollTop > 100;

      // If user scrolls up, disable auto-scroll
      if (!atBottom) {
        this.shouldThreadAutoScroll = false;
      }

      // Save thread scroll position
      if (this.activeThread && this.activeThread.id) {
        this.threadScrollPositions[this.activeThread.id] = element.scrollTop;
        localStorage.setItem('threadScrollPositions', JSON.stringify(this.threadScrollPositions));
      }
    },

    scrollThreadToTop() {
      const threadMessages = document.querySelector('.thread-messages');
      if (threadMessages) {
        threadMessages.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },

    restoreThreadScrollPosition() {
      this.$nextTick(() => {
        const threadMessages = document.querySelector('.thread-messages');
        if (threadMessages && this.activeThread && this.activeThread.id) {
          const savedPosition = this.threadScrollPositions[this.activeThread.id] || 0;
          threadMessages.scrollTop = savedPosition;
        }
      });
    },

    isAtBottom(element) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      return Math.abs(scrollHeight - scrollTop - clientHeight) < 50;
    },

    setupScrollObservers() {
      // Main chat observer
      this.mainScrollObserver = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          const shouldAutoScroll = this.isMainAtBottom && !entry.isIntersecting;

          if (shouldAutoScroll) {
            this.scrollToBottom(this.$refs.mainChatArea);
          }
        },
        {
          root: this.$refs.mainChatArea,
          threshold: 0,
        }
      );

      // Thread observer
      this.threadScrollObserver = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          const shouldAutoScroll = this.isThreadAtBottom && !entry.isIntersecting;

          if (shouldAutoScroll) {
            this.scrollToBottom(this.$refs.threadChatArea);
          }
        },
        {
          root: this.$refs.threadChatArea,
          threshold: 0,
        }
      );

      // Observe anchors only if they exist
      this.$nextTick(() => {
        const mainAnchor = this.$refs.mainScrollAnchor;
        const threadAnchor = this.$refs.threadScrollAnchor;

        if (mainAnchor && mainAnchor instanceof Element) {
          this.mainScrollObserver.observe(mainAnchor);
        }

        if (threadAnchor && threadAnchor instanceof Element) {
          this.threadScrollObserver.observe(threadAnchor);
        }
      });
    },

    handleMainScroll(event) {
      const element = event.target;
      const atBottom = this.isAtBottom(element);
      this.showScrollTop = element.scrollTop > 100;

      // If user scrolls up, disable auto-scroll
      if (!atBottom) {
        this.shouldMainAutoScroll = false;
      }
    },

    scrollToBottom(element) {
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    },

    // Unified scroll handling
    handleScroll(event, type = 'main') {
      const element = event.target;
      const atBottom = this.isAtBottom(element);

      // Update auto-scroll flag based on type
      if (type === 'main') {
        this.shouldMainAutoScroll = atBottom;
      } else {
        this.shouldThreadAutoScroll = atBottom;
      }

      // Save scroll positions
      if (type === 'main') {
        this.scrollPositions.main = element.scrollTop;
      } else if (this.activeThread && this.activeThread.id) {
        this.scrollPositions.threads[this.activeThread.id] = element.scrollTop;
      }

      // Save to localStorage
      localStorage.setItem('chatScrollPositions', JSON.stringify(this.scrollPositions));
    },

    // Unified scroll to bottom
    scrollToLastMessage(type = 'main') {
      const ref = type === 'main' ? this.$refs.mainChatArea : this.$refs.threadChatArea;
      const shouldScroll = type === 'main' ? this.shouldMainAutoScroll : this.shouldThreadAutoScroll;

      if (ref && shouldScroll) {
        this.scrollToBottom(ref);
      }
    },

    // Restore scroll positions
    restoreScrollPosition(type = 'main') {
      this.$nextTick(() => {
        const ref = type === 'main' ? this.$refs.mainChatArea : this.$refs.threadChatArea;
        if (!ref) return;

        if (type === 'main') {
          ref.scrollTop = this.scrollPositions.main || 0;
        } else if (this.activeThread && this.activeThread.id) {
          ref.scrollTop = this.scrollPositions.threads[this.activeThread.id] || 0;
        }
      });
    },

    // Load scroll positions on component creation
    loadScrollPositions() {
      try {
        const savedPositions = localStorage.getItem('chatScrollPositions');
        if (savedPositions) {
          this.scrollPositions = JSON.parse(savedPositions);
        }
      } catch (error) {
        console.error('Error loading scroll positions:', error);
        this.scrollPositions = { main: 0, threads: {} };
      }
    },
  },
  watch: {
    showThreadDialog(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.restoreScrollPosition('thread');
          this.setupScrollObservers();
        });
      } else {
        this.$forceUpdate();
      }
    },
    threads: {
      handler() {
        // Force component update
        this.$forceUpdate();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.parent-messages {
  overflow-y: auto;
  position: relative;
  height: 750px;
  flex-grow: 1;
}

.parent-dialog-card {
  height: 100vh;
  max-height: 750px;
}

.parent-dialog-card :deep(.v-card__text) {
  overflow-y: auto;
  padding: 16px;
}

.parent-dialog-card :deep(.v-card__actions) {
  padding: 16px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.thread-messages {
  overflow-y: auto;
  position: relative;
  height: 400px;
  flex-grow: 1;
}

.thread-dialog-card {
  height: 100vh;
  max-height: 600px;
}

.thread-dialog-card :deep(.v-card__text) {
  overflow-y: auto;
  padding: 16px;
}

.thread-dialog-card :deep(.v-card__actions) {
  padding: 16px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

/* When in fullscreen mode */
:deep(.v-dialog--fullscreen) .thread-dialog-card {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

:deep(.v-dialog--fullscreen) .thread-dialog-card .v-card__text {
  flex: 1;
  height: calc(100vh - 128px);
  /* Subtract toolbar and input field heights */
}

.parent-message {
  border-left: 3px solid #179fdb;
}

.message-container {
  max-width: 80%;
}

.message-d-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.toolbar-fixed {
  max-height: 64px;
}

.toolbar-fixed :deep(.v-toolbar__content) {
  max-height: 64px;
  padding-top: 0;
  padding-bottom: 0;
}

.scroll-top-button {
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(23, 159, 219, 0.9);
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;
  font-size: 0.875rem;
  transition: opacity 0.3s;
}

.scroll-top-button:hover {
  background-color: rgba(23, 159, 219, 1);
}

.scroll-anchor {
  height: 1px;
  width: 100%;
}
</style>