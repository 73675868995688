<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-spacer />
      <h3 style="color: #0e5082" class="font-weight-light">PX Data</h3>
      <v-spacer />
      <h4 v-if="signedIn" style="color: #0e5082" class="font-weight-light">
        Hallo, {{ user.given_name }} ({{ kurzel }})
      </h4>
      <div v-if="signedIn || isLocal">
        <!-- ai chatbot -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" tile icon color="#179fdb" @click.stop="openChatbot = true">
              <v-icon> mdi-chat-plus </v-icon>
            </v-btn>
          </template>
          <span>platform X - CoPilot</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon tile color="primary" @click="onSettingsClick">
              <v-icon> mdi-cog </v-icon>
            </v-btn>
          </template>
          <span>Benutzerdaten</span>
        </v-tooltip>

      </div>

      <v-tooltip bottom v-if="!signedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="isLocal" v-bind="attrs" v-on="on" tile color="success" @click="onLoginClick">
            <v-icon left> mdi-login </v-icon>
            Bitte Logge dich ein!
          </v-btn>
        </template>
        <span>Einloggen</span>
      </v-tooltip>
      <v-tooltip bottom v-if="signedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" tile icon color="error" @click="onLogoutClick">
            <v-icon> mdi-logout </v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-row>
    <div v-if="signedIn || isLocal">
      <ChatBot v-model="openChatbot" />
    </div>
  </v-container>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import { mapGetters } from 'vuex';

// Arvato start
import '@aws-amplify/ui-components';
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader';
import Vue from 'vue';
import ChatBot from './ChatBot.vue';

Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
  defineCustomElements(window);
});
// Arvato end

export default {
  components: { ChatBot },
  data: () => ({
    openChatbot: false,
    isLocal: process.env.VUE_APP_ENV === undefined,
  }),
  computed: {
    email() {
      if (this.user) {
        return this.user;
      }
      return '';
    },
    ...mapGetters(['user', 'signedIn', 'kurzel']),
  },
  // created(){
  // console.log('creacted!')
  //   this.$store.commit('setSignedIn', false);
  //   this.$store.commit('setUser', null);
  //   this.$store.commit('setJwtContent', '');
  // },
  mounted() {
    console.log('App mounted()');
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log(`Auth event: ${event}`);
      this.$store.commit('setSignedIn', false);
      this.$store.commit('setUser', null);
      switch (event) {
        case 'signIn':
          console.log('signIn data: ' + JSON.stringify(data));
          // console.log('signIn data: ' + JSON.stringify(data.signInUserSession.accessToken.payload));

          // arvato
          // data.username = data.attributes.email;
          // arvato

          // this.$store.commit('setUser', data.username);
          // console.log('userNAME: '+data.username);
          // this.$store.commit('setJwtContent', data.signInUserSession.accessToken.payload);
          // this.$store.commit('setSignedIn', true);
          Auth.currentAuthenticatedUser()
            .then((user) => {
              console.log('currentAuthenticatedUser');
              console.log(user);
              console.log('Username: ' + user.attributes);
              console.log('Refreshing access and id tokens');
              Auth.currentSession()
                .then((data) => console.log(data))
                .catch((err) => console.log('Error refreshing tokens: ' + err));
              // this.$store.commit('setSignedIn', true);
              this.$store.commit('setUser', user.attributes);
              this.$store.commit('setJwtContent', user.signInUserSession.accessToken.payload);
              this.$store.commit('setJwtToken', user.signInUserSession.accessToken.jwtToken);
              if (this.kurzel !== 'unbekannt') {
                this.$store.commit('setSignedIn', true);
              } else {
                this.$store.commit('setSignedIn', false);
              }
            })
            .catch((err) => console.log('Auth.currentAuthenticatedUser(): ' + err));

          break;
        case 'signOut':
          this.$store.commit('setSignedIn', false);
          this.$store.commit('setUser', null);
          this.$store.commit('setJwtContent', '');
          this.$store.commit('setJwtToken', '');
          break;

        case 'customOAuthState':
          // go to custom state
          this.$router.push(data);
          break;
        case 'signIn_failure':
          this.onLoginClick();
      }
    });
    // Auth.currentAuthenticatedUser()
    //   .then((user) => {
    //     console.log('currentAuthenticatedUser');
    //     console.log(user);
    //     console.log('Username: ' + user.username);
    //     console.log('Refreshing access and id tokens');
    //     Auth.currentSession()
    //       .then((data) => console.log(data))
    //       .catch((err) => console.log('Error refreshing tokens: ' + err));
    //     this.$store.commit('setSignedIn', true);
    //     this.$store.commit('setUser', user.attributes);
    //     this.$store.commit('setJwtContent', user.signInUserSession.accessToken.payload);
    //   })
    //   .catch((err) => console.log('Auth.currentAuthenticatedUser(): ' + err));
  },
  methods: {
    onLoginClick() {
      Auth.federatedSignIn({ provider: process.env.VUE_APP_COGNITO_PROVIDER }).catch((err) => {
        console.log('DEBUG: ' + JSON.stringify(err));
      });
    },
    onLogoutClick() {
      console.log('signing out');
      Auth.signOut({ global: true })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
          if (err.includes('NotAuthorizedException')) {
            Auth.signOut();
          }
        });
    },
    onSettingsClick() {
      this.$router.push({ name: 'Settings' });
    },
  },
};
</script>